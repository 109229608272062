<!--

      This view displays the login page.

 -->

<template>
  <SignInUpPage action="login">
    <div class="back-button-area" @click="this.$router.go(-1)">
      <Icon name="foodableArrowBack" />
    </div>

    <div class="signinup-content">
      <div class="headline">Mit E-Mail einloggen</div>

      <div class="fields">
        <div class="input-field">
          <Icon name="personOutline" class="icon-input" />
          <ion-input @ionChange="setEmail($event)" placeholder="E-Mail" pattern="email" inputmode="email" type="email" data-cy="email"></ion-input>
        </div>
        <div class="input-field">
          <Icon name="lockOpenOutline" class="icon-input" />
          <ion-input ref="pw1" v-on:keyup.enter="this.login()" @ionChange="setPassword($event)" placeholder="Passwort" pattern="password" inputmode="password" type="password" data-cy="login-password"></ion-input>
        </div>
        <div class="text-right btn-tertiary-small forgot-password" @click="goTo('/resetpassword')">Passwort vergessen</div>
      </div>

      <div class="btn-primary" type="submit" @click="this.login()" data-cy="submit">
        <span v-if="!$store.state.enteringTheApp">Weiter</span>
        <ion-spinner v-else name="crescent" class="login-spinner"></ion-spinner>
      </div>
      <div class="btn-tertiary" @click="this.$store.dispatch('writeHelpMail')">Ich brauche Hilfe / habe Feedback</div>
    </div>
  </SignInUpPage>
</template>

<script>
import SignInUpPage from '@/components/SignInUpPage.vue'
import { IonInput, IonSpinner } from '@ionic/vue'

export default {
  name: 'login',
  components: {
    SignInUpPage,
    IonInput,
    IonSpinner
  },
  data () {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    goTo (name) {
      this.$store.dispatch('goTo', name)
    },
    setEmail (event) {
      if (event.target.value !== '') {
        this.email = event.target.value
      }
    },
    setPassword (event) {
      if (event.target.value !== '') {
        this.password = event.target.value
      }
    },
    async login () {
      if (this.email && this.password) {
        const details = {
          url: this.$store.state.api.auth.login,
          id: '',
          data: {
            grant_type: "password",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: this.email,
            password: this.password
          },
          method: 'POST',
          noauthheader: true
        }
        this.$store.dispatch('apiSend', details).then( auth => {
          if ( auth && auth.access_token ) {
            this.$store.dispatch('save', { key: 'refreshtoken', value: auth.refresh_token })
            this.$store.dispatch('save', { key: 'accesstoken', value: auth.access_token }).then(() => {
              this.$store.dispatch('runThisUponSuccessfulLogin')
            })
          } else if ( auth ) {
            this.$store.dispatch('showNotification', { message: 'E-Mail oder Passwort ist falsch.', type: 'Error' })
          } else {
            this.$store.dispatch('showNotification', { message: 'Die Anmeldung war nicht erfolgreich. Bitte versuche es noch einmal.', type: 'Error' })
          }
        })
      } else {
        this.$store.dispatch('showNotification', { message: 'Bitte gib eine E-Mail Adresse und ein Passwort ein.', type: 'Error' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.forgot-password {
  margin-top: -6px;
}
.login-spinner {
  --color: white;
  height: 22px;
  margin: -6px 0;
}
</style>
